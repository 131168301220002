body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Content {
  min-height: 100vh;
  width: 100%;
  display: flex;
}

.Outlet {
  width: 100%;
  overflow: auto;
  padding: 1em;
  background: #f8f8f8;
}

.padding-left {
  padding-left: 1em;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: white;
  border: 1px solid #666;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 2px #666;
  padding: 20px;
}

.custom-tooltip {
  padding-left: 20px;
}

.drop-area {
  width: 99%;
  color: #ccc;
  height: 100px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.device-image {
  max-width: 99%;
  max-height: 200px;
  object-fit: cover;
  margin: 20px 0;
}

.background-white {
  background-color: white;
}

.active-button {
  color: black !important;
  transform: scale(1.1);
}

.box-detail{
  display:flex; 
  flex-grow: 1;
  justify-content: end;
  align-items: center;
}

.detail{
  font-size: 14px; 
  cursor: pointer;
  justify-content: end;
  color: #007bff
}

.hover-d{
   padding: 2px 4px;
   transition: all 0.3s ease-out; 
}

.hover-d:hover {
   color: #0160c5;
   background-color: #fcfcfc;
   border-radius: 5px;
}

.discount-label{
  background-color: #ffc552; 
  color: #6c2e12; 
  border-radius: 5px; 
  padding: 5px; 
  font-weight: bold;
  font-size: x-small;
  width: 30px;
  text-align: center;
}

.shadow {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.detail-close-btn{
  max-height: 50px;
  text-align: right;
  color: white;
  padding: 12px;
}

@media (max-width: 992px) {
  .Outlet{
      height: auto;
      overflow: hidden;
  }
}